<template>
  <div>
    <slot
      name="enabled"
      :onEnabledChange="onEnabledChange"
      :value="enabled"
    ></slot>

    <datetime-picker
      placeholder="action.time_range"
      range
      v-model="filterValue"
    ></datetime-picker>
  </div>
</template>

<script lang="babel" type="text/babel">
import filter from '@/components/list/listSearchAndFilter/filter.js'
export default {
  mixins: [filter],
  computed: {
    storeFilterValueValid() {
      if(!this.storeFilterValue) return true
      if(!Array.isArray(this.storeFilterValue)) return false
      if(this.storeFilterValue.length != 2) return false
      const start = this.storeFilterValue[0]
      const end = this.storeFilterValue[1]
      if(!start || !end) return false
      return true
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>